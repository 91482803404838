<template>
  <div class="vocabContainer">
    <div class="header">
      <div class="back">
        <span @click="backToPreviousPage" class="action-icon">
          <i class="fas fa-chevron-left" />
        </span>
      </div>
      <div class="title">
        <h1>{{ pageTitle }}</h1>
      </div>
    </div>
    <div class="vocabularies">
      <div
        class="vocabulary"
        :key="vocabulary.id"
        v-for="vocabulary in vocabList.vocabularies"
      >
        <div
          class="wordAndMeaning"
          @click="() => openVocabularyDrawer(vocabulary.id)"
        >
          <div class="word">
            <strong>{{ vocabulary.vocab_front.word }}</strong>
          </div>
          <div class="meaning">
            {{ `(${vocabulary.pos}) ${getI18nMeaning(vocabulary.meaning)}` }}
          </div>
        </div>
        <div class="pronounce">
          <div>
            US
            <span
              @click="() => speakWord(vocabulary.vocab_front.word, 'en-US')"
            >
              <i class="fas fa-play-circle" />
            </span>
          </div>
          <div>
            UK
            <span
              @click="() => speakWord(vocabulary.vocab_front.word, 'en-GB')"
            >
              <i class="fas fa-play-circle" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <VocabularyDrawer
      :opened="openedVocabylaryDrawer"
      :vocabulary="displayVocabulary"
      @closeVocabularyDrawer="() => displayVocabularyId = null"
    />
  </div>
</template>

<script>
import { speakWord } from "@/views/toefl/flashcards/speak.js";
import VocabularyDrawer from "@/views/toefl/flashcards/VocabularyDrawer.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ` + this.CompanyName
    };
  },
  components: {
    VocabularyDrawer
  },
  computed: {
    pageIsFlashCard() {
      return this.$route.name === "FlashcardsVocabulariesList";
    },
    pageTitle() {
      return this.vocabList.list?.title || "";
    },
    vocabList() {
      return this.$store.getters["flashcard/vocabList"];
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    speakWord() {
      return speakWord;
    },
    openedVocabylaryDrawer() {
      return Boolean(this.displayVocabularyId);
    },
    displayVocabulary() {
      return (this.vocabList.vocabularies || []).find(
        vocabulary => vocabulary.id === this.displayVocabularyId
      );
    }
  },
  data() {
    return {
      displayVocabularyId: null
    };
  },
  async created() {
    try {
      if (this.pageIsFlashCard) {
        await this.$store.dispatch(
          "flashcard/fetchVocabList",
          this.$route.query.listId
        );
      } else {
        await this.$store.dispatch(
          "flashcard/fetchCollectionVocabularies",
          this.$route.query.listId
        );
      }
    } catch (e) {
      if (e.data.message === "not_own_pro_plan") {
        this.$router.push({ name: "PageNotFound" });
      }
      return;
    }

    if (this.vocabList.vocabularies.length === 0) {
      this.$confirm(this.$t("flashCard.i18n41"), this.$t("message.notice"), {
        confirmButtonText: this.$t("flashCard.i18n49"),
        cancelButtonText: this.$t("flashCard.i18n19"),
        type: "warning"
      })
        .then(() => { this.$router.push({ name: "Flashcards" }); })
        .catch(() => { this.$router.push({ name: "MyFlashcards" }); });
    };

    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
  },
  beforeRouteLeave(to, from, next) {
    next();

    // reset main padding and set vocabularies position
    const main = document.getElementsByClassName("el-main");
    main[0].style.paddingTop = "100px";
    const vocabularies = document.getElementsByClassName("vocabularies")[0];
    vocabularies.style.marginTop = "-40px";
  },
  methods: {
    backToPreviousPage() {
      this.$router.go(-1);
    },
    getI18nMeaning(meaning) {
      if (this.lang === "en") {
        return meaning.tw;
      } else {
        return meaning[this.lang];
      }
    },
    openVocabularyDrawer(vocabularyId) {
      this.displayVocabularyId = vocabularyId;
    }
  }
};
</script>

<style scoped lang="scss">
.vocabContainer {
  width: 100vw;
}

.header {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0px;
  border-bottom: 1px solid #e0e0e0;
  display: grid;
  grid-template-columns: 52px 1fr;
  background-color: #fafafa;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    color: var(--themeColor);
    font-size: 32px;
  }

  .title {
    h1 {
      font-size: 24px;
    }
  }
}

.vocabularies {
  width: 100vw;
  margin-top: 60px;
  height: calc(100% - 60px);

  .vocabulary {
    padding: 12px 15px;
    cursor: pointer;
    font-size: 18px;
    border-bottom: 1px solid #e0e0e0;
    color: var(--themeColor);
    display: flex;
    flex-wrap: nowrap;

    .wordAndMeaning {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 300px 1fr;
    }

    .pronounce {
      display: flex;
      align-items: center;
      div {
        border-left: 1px solid #e0e0e0;
        padding: 0px 8px;
        span {
          color: var(--themeColor);
          cursor: pointer;
        }
      }
    }
  }

  .vocabulary:hover {
    background: var(--themeColor);
    color: #fff;

    .pronounce {
      div {
        span {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .vocabularies {
    .vocabulary {
      .wordAndMeaning {
        flex-grow: 1;
        display: grid;
        grid-template-columns: unset;
        grid-template-rows: 1fr 1fr;
      }
    }
  }
}
</style>
